<template>
    <div>
        <Navbar page="Parcerias para Projetos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="grid grid-cols-12 gap-6 mt-4">
                <div class="col-span-12">
                    <p class="text-base font-normal">
                        Aqui você encontra profissionais para auxiliar em seus projetos.
                    </p>
                    <p class="text-base font-normal">
                        Clique em <b>"entrar em contato"</b> e converse diretamente com o(a) profissional escolhido.
                    </p>
                </div>
            </div>
            <hr class="mt-8 mb-4 border-gray-800" />
            <div class="grid grid-cols-12 gap-4 mt-4">
                <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                    <div class="grid grid-cols-12">
                        <div class="col-span-5 flex items-center justify-center">
                            <img
                                src="@/assets/natalia.jpeg"
                                alt="icon-avatar"
                                class="w-20 h-20 md:w-28 md:h-28 rounded-full"
                            />
                        </div>
                        <div class="col-span-7">
                            <p class="text-lg font-normal">
                                Natalia Rockembach
                            </p>
                            <p class="text-base font-normal">
                                <b>Expert em:</b> Entrevistas e Mapeamento de dados
                            </p>
                            <p class="text-base font-normal">
                                <b>Atua:</b> Online e presencial
                            </p>
                            <p class="text-base font-normal">
                                <b>Região:</b> Ponta Grossa (PR)
                            </p>
                            <a type="button" target="_blank" href="https://api.whatsapp.com/send?phone=5542999266612&text=F%C3%81CIL%20LGPD%3A%20Tenho%20interesse%20em%20adquirir%20uma%20Consultoria%20LGPD" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Entre em contato
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                    <div class="grid grid-cols-12">
                        <div class="col-span-5 flex items-center justify-center">
                            <img
                                src="@/assets/ruiter.jpeg"
                                alt="icon-avatar"
                                class="w-20 h-20 md:w-28 md:h-28 rounded-full"
                            />
                        </div>
                        <div class="col-span-7">
                            <p class="text-lg font-normal">
                                José Ruiter Cordeiro Jr
                            </p>
                            <p class="text-base font-normal">
                                <b>Expert em:</b> Projetos ponta a ponta
                            </p>
                            <p class="text-base font-normal">
                                <b>Atua:</b> Online e presencial
                            </p>
                            <p class="text-base font-normal">
                                <b>Região:</b> Ponta Grossa (PR)
                            </p>
                            <a type="button" target="_blank" href="https://api.whatsapp.com/send?phone=554291097240&text=F%C3%81CIL%20LGPD%3A%20Tenho%20interesse%20em%20adquirir%20uma%20Consultoria%20LGPD" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Entre em contato
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                    <div class="grid grid-cols-12">
                        <div class="col-span-5 flex items-center justify-center">
                            <img
                                src="@/assets/forbusiness.png"
                                alt="icon-avatar"
                            />
                        </div>
                        <div class="col-span-7">
                            <p class="text-lg font-normal">
                                4Business Ltda
                            </p>
                            <p class="text-base font-normal">
                                <b>Expert em:</b> Digital, LGPD, Empresarial, Internacional, Propriedade Intelectual (Marcas e Patentes), Cível e Administrativo.
                            </p>
                            <p class="text-base font-normal">
                                <b>Atua:</b> Online e presencial
                            </p>
                            <p class="text-base font-normal">
                                <b>Região:</b> Pernambuco, Goiânia, Rio Grande do Sul e Chile.
                            </p>
                            <a type="button" target="_blank" href="https://api.whatsapp.com/send?phone=554291097240&text=F%C3%81CIL%20LGPD%3A%20Tenho%20interesse%20em%20adquirir%20uma%20Consultoria%20LGPD" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Entre em contato
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                    <div class="grid grid-cols-12">
                        <div class="col-span-5 flex items-center justify-center">
                            <img
                                src="@/assets/itala.jpeg"
                                alt="icon-avatar"
                                class="w-20 h-20 md:w-28 md:h-28 rounded-full"
                            />
                        </div>
                        <div class="col-span-7">
                            <p class="text-lg font-normal">
                                Itala Natacha Souza da Silva Bueno Guerreiro
                            </p>
                            <p class="text-base font-normal">
                                <b>Expert em:</b> Direito Digital, Direito Empresarial e Direito Cível.
                            </p>
                            <p class="text-base font-normal">
                                <b>Atua:</b> Online e presencial
                            </p>
                            <p class="text-base font-normal">
                                <b>Região:</b> Goiânia (GO)
                            </p>
                            <a type="button" target="_blank" href="https://api.whatsapp.com/send?phone=556292291328&text=F%C3%81CIL%20LGPD%3A%20Tenho%20interesse%20em%20adquirir%20uma%20Consultoria%20LGPD" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Entre em contato
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                    <div class="grid grid-cols-12">
                        <div class="col-span-5 flex items-center justify-center">
                            <img
                                src="@/assets/thais.jpeg"
                                alt="icon-avatar"
                                class="w-20 h-20 md:w-28 md:h-28 rounded-full"
                            />
                        </div>
                        <div class="col-span-7">
                            <p class="text-lg font-normal">
                                Dra. Thaís Carnieletto Muller
                            </p>
                            <p class="text-base font-normal">
                                <b>Expert em:</b> Direito Digital, Direito Empresarial e Direito de Propriedade Intelectual. 
                            </p>
                            <p class="text-base font-normal">
                                <b>Atua:</b> Online e presencial
                            </p>
                            <p class="text-base font-normal">
                                <b>Região:</b> Rio Grande do Sul (RS) / Chile
                            </p>
                            <a type="button" target="_blank" href="https://api.whatsapp.com/send?phone=555181375256&text=F%C3%81CIL%20LGPD%3A%20Tenho%20interesse%20em%20adquirir%20uma%20Consultoria%20LGPD" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Entre em contato
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                    <div class="grid grid-cols-12">
                        <div class="col-span-5 flex items-center justify-center">
                            <img
                                src="@/assets/talitha.jpeg"
                                alt="icon-avatar"
                                class="w-20 h-20 md:w-28 md:h-28 rounded-full"
                            />
                        </div>
                        <div class="col-span-7">
                            <p class="text-lg font-normal">
                                Talitha Dias Martins Leite
                            </p>
                            <p class="text-base font-normal">
                                <b>Expert em:</b> Direito Digital, Direito Empresarial e Direito Internacional
                            </p>
                            <p class="text-base font-normal">
                                <b>Atua:</b> Online e presencial
                            </p>
                            <p class="text-base font-normal">
                                <b>Região:</b> Pernambuco(PE)
                            </p>
                            <a type="button" target="_blank" href="https://api.whatsapp.com/send?phone=558196824444&text=F%C3%81CIL%20LGPD%3A%20Tenho%20interesse%20em%20adquirir%20uma%20Consultoria%20LGPD" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Entre em contato
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-8 mb-4 border-gray-800" />
            <h2 class="text-2xl mb-3 font-semibold">Ofereça você também seus serviços, é rápido, é FÁCIL</h2>
            <button @click="$modal.show('solicitacaoModal')" type="button" class="inline-flex justify-center py-2 px-4 my-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Faça uma solicitação
            </button>
        </div>
        <modal name="solicitacaoModal" :adaptive="true" :height="'auto'">
        <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
            <form action="#" method="POST">
                <div class="px-3 pb-4">
                    <h2 class="text-2xl mb-3 font-semibold">Ofereça você também seus serviços</h2>
                    <label for="nome" class="block text-sm font-medium">Email de contato</label>
                    <input v-model="form.email" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                    <label for="phone" class="mt-2 block text-sm font-medium">Telefone Celular</label>
                    <the-mask :mask="['(##) ####-####', '(##) #####-####']" masked v-model="form.telefone" placeholder="(99) 99999-9999"  id="phone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                    <label for="obs" class="mt-2 block text-sm font-medium">Comentário</label>
                    <textarea v-model="form.obs" rows="3" type="text" name="obs" id="obs" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                    <div class="grid grid-cols-2 mt-2">
                        <div class="pt-3 text-left">
                            <button @click="$modal.hide('solicitacaoModal')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="pt-3 text-right">
                            <button @click="novaSolicitacao" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-500">
                                Enviar Solicitação
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                tipo: 'Tornar-se Consultor',
                email: '',
                telefone: '',
                obs: ''
            }
        }
    },
    methods: {
        async novaSolicitacao (){
            const req = await this.$http.post(`/v1/solicitacao`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Solicitação enviada com sucesso!");
                this.$modal.hide('solicitacaoModal');
                this.form = { email: '', telefone: '', obs: ''};
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    }
}
</script>